<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent>
          <b-form-group label="Menu Name" label-for="name">
            <b-form-input
              id="name"
              trim
              placeholder="About Us"
              v-model="menuItemDetails.name"
            />
          </b-form-group>
        </b-form>
      </b-col>
      <b-col cols="12">
        <b-form @submit.prevent>
          <b-form-group label="Link Address" label-for="link">
            <b-form-input
              dir="auto"
              id="link"
              trim
              placeholder="about-us"
              v-model="menuItemDetails.link"
            />
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <div class="w-100 d-flex align-items-center justify-content-end">
      <b-button
        :disabled="menuId ? false : true"
        variant="success"
        size="sm"
        @click="sendCreateMenuItemRequest"
      >
        Create
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
export default {
  emits: ["refetch"],
  props: {
    menuId: {
      type: [String, Number],
    },
    lastPriority: {
      type: [String, Number],
    },
    languageId: {
      type: [String, Number],
      required: false,
    },
  },

  data() {
    return {
      isLoading: false,
      menuItemDetails: {
        isDeleted: false,
        menuItemId: 0,
        menuId: null,
        mainId: "",
        name: null,
        link: null,
        seourl: null,
        seoTitle: null,
        seoDescription: null,
        languageId: null,
        pageId: null,
        parentId: null,
        priority: null,
        createDate: new Date(Date.now()).toISOString(),
      },
    };
  },
  methods: {
    async sendCreateMenuItemRequest() {
      try {
        if (this.menuItemDetails.link && this.menuItemDetails.link != "#") {
          if (!this.menuItemDetails.link.startsWith("/")) {
            this.menuItemDetails.link = `/${this.menuItemDetails.link}`;
          }
        }
        this.isLoading = true;
        this.menuItemDetails.languageId = this.languageId;
        this.menuItemDetails.menuId = this.menuId;
        this.menuItemDetails.priority = this.lastPriority
          ? this.lastPriority + 1
          : 1;
        let _this = this;
        let createNewMenuItem = new CreateNewMenuItem(_this);
        createNewMenuItem.setRequestParamDataObj(_this.menuItemDetails);
        await createNewMenuItem.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Menu Created Successfully",
              },
            });
            _this.menuItemDetails = {
              isDeleted: false,
              menuItemId: 0,
              menuId: null,
              name: null,
              link: null,
              seourl: null,
              seoTitle: null,
              seoDescription: null,
              mainId: "",
              pageId: null,
              parentId: null,
              priority: null,
              createDate: new Date(Date.now()).toISOString(),
            };
            _this.$emit("refetch");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BButton,
    LanguagePicker,
  },
};
import { CreateNewMenuItem } from "@/libs/Api/MenuItem";
import LanguagePicker from "../utils/LanguagePicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BOverlay,
} from "bootstrap-vue";
</script>
